import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_HOMEPAGE_CAROUSEL_BANNERS, GET_OTHER_SERVICES_TITLE } from '@graphql/queries/homepageQueries'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import times from 'lodash/times'

import Relazsizes from 'relazsizes'

import SectionTitle from 'components/common/atoms/SectionTitle'
import Loader from 'components/common/atoms/Loader'
import SectionContainer from 'components/layouts/atoms/SectionContainer'
import Container from 'components/layouts/atoms/Container'
import MobileCarousel from 'components/common/organisms/MobileCarousel'
import { useRouter } from 'next/router'
import AuthenticatedLink from 'components/common/molecules/AuthenticatedLink'
import { triggerEvent } from 'utils/dataLayer'

type Banner = {
  id: string
  name: string
  url: string
  recordLead: boolean
  action: string
  bigImageDesktop: {
    url: string
  }
  bigImageMobile: {
    url: string
  }
  brochure: {
    url: string
  } | null
  homepageType: string
}

const otherServiceStyle = (theme: ThemeType) => css`
  .other-service {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
    }
    &__img {
      &:not(:last-of-type) {
        margin-right: 16px;
      }
      img,
      svg {
        width: 100%;
        border-radius: 10px;
        background-color: ${theme.gray.gray20};
        @media screen and (min-width: 768px) {
          width: 386px;
          min-height: 192px;
          object-fit: cover;
        }
      }
    }

    @media screen and (max-width: 767px) {
      &__wrapper {
        flex-direction: column;
        display: none;
      }
    }
  }
`

const queryVars = {
  imageDesktopSize: '772x360',
  imageMobileSize: '720x280',
}

const OtherService = () => {
  const theme: ThemeType = useTheme()
  const router = useRouter()
  const { data: bannersResult, loading } = useQuery(GET_HOMEPAGE_CAROUSEL_BANNERS, {
    variables: queryVars,
  })
  const { data: homepageData } = useQuery(GET_OTHER_SERVICES_TITLE)

  const banners = bannersResult?.banners
  const otherServicesTitle = homepageData?.homepage?.otherServicesTitle
  const otherServices = banners?.filter((banner: Banner) => banner.homepageType === 'service')

  if (loading) {
    return (
      <div className="other-service" css={otherServiceStyle(theme)}>
        <SectionContainer smallPadding>
          <Container>
            <SectionTitle>
              <Loader type="title" dataTestId="service-title-skeleton" />
              <div className="other-service__wrapper">
                {times(3, (i) => (
                  <Loader
                    type="smallBanner"
                    className="other-service__img"
                    key={`service-skeleton-${i}`}
                    dataTestId={`service-skeleton-${i}`}
                  />
                ))}
              </div>
            </SectionTitle>
          </Container>
          <div
            className="mobile-carousel"
            css={css`
              margin-top: 24px;
              @media screen and (min-width: 768px) {
                display: none;
              }
            `}
          >
            <MobileCarousel banners={bannersResult} />
          </div>
        </SectionContainer>
      </div>
    )
  } else if (otherServices?.length > 1) {
    const getUrl = (banner: Banner) => {
      if (banner.action !== 'url') {
        return banner.brochure?.url
      }
      return banner.url
    }

    const isOpenNewTab = (bannerAction: Banner['action']) => {
      return bannerAction !== 'url'
    }

    const handleClick = (banner: Banner) => {
      triggerEvent('home_other_banner', { banner_name: banner.name })
      isOpenNewTab(banner.action)
        ? window.open(getUrl(banner) || '#', 'blank', 'noopener noreferrer')
        : router.push(getUrl(banner) || '#')
    }
    return (
      <div className="other-service" css={otherServiceStyle(theme)}>
        <SectionContainer smallPadding>
          <Container isMobileFull>
            <SectionTitle>{otherServicesTitle || 'Layanan Unggulan Lainnya'}</SectionTitle>
            <div className="other-service__wrapper">
              {otherServices.map((banner: Banner, index: number) => (
                <AuthenticatedLink
                  dataTestId={`other-services-banner-${index}`}
                  className="other-service__img"
                  key={banner?.id}
                  onClick={() => handleClick(banner)}
                  skipAuth={!banner.recordLead}
                >
                  <Relazsizes
                    alt={banner?.name}
                    src="next-assets/images/skeleton.svg"
                    el="picture"
                    dataSrcset={[
                      {
                        media: '(min-width: 576px)',
                        srcset: banner?.bigImageDesktop?.url,
                        alt: banner?.name,
                      },
                    ]}
                    dataSrc={banner?.bigImageMobile?.url || banner?.bigImageDesktop?.url}
                  />
                </AuthenticatedLink>
              ))}
            </div>
          </Container>
          <div
            className="mobile-carousel"
            css={css`
              margin-top: 24px;
              @media screen and (min-width: 768px) {
                display: none;
              }
            `}
          >
            <MobileCarousel banners={otherServices} />
          </div>
        </SectionContainer>
      </div>
    )
  }
  return null
}

export default OtherService
