import React, { useContext, useEffect } from 'react'

import { ProfileCtx } from 'components/utils/ProfileProvider'

import SeoText from './SeoText'
import OtherService from './OtherService'
import PromotionBanner from './PromotionBanner'
import WhyQC from './WhyQC'
import RecommendedCampus from './RecommendedCampus'
import RecommendedMajors from './RecommendedMajors'
import RecommendedCareers from './RecommendedCareers'
import RecommendedTests from './RecommendedTests'
import RecommendedArticles from './RecommendedArticles'
import RecommendedCities from './RecommendedCities'
import MenuSection from './MenuSection'
import BannersCarousel from './BannersCarousel'
import { NotificationCtx, ActionType } from 'components/utils/NotificationProvider'

const Home = () => {
  const { state: profileState } = useContext(ProfileCtx)
  const { dispatch } = useContext(NotificationCtx)
  useEffect(() => {
    dispatch({
      type: ActionType.SET_SHOW_MOBILE_NOTIFICATION,
    })
  }, [])

  return (
    <>
      <BannersCarousel />
      <MenuSection />
      <PromotionBanner />
      <RecommendedCampus />
      <RecommendedMajors />
      {profileState.authStatus !== 'authorized' && <WhyQC />}
      <OtherService />
      <RecommendedCareers />
      <RecommendedTests />
      <RecommendedCities />
      <RecommendedArticles />
      <SeoText />
    </>
  )
}

export default Home
