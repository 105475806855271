/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

import Container from 'components/layouts/atoms/Container'
import Relazsizes from 'relazsizes'

import { useCarousel } from 'utils/hooks/useCarousel'
import { css } from '@emotion/core'
import flexCenter from 'utils/styles/flexCenter'
import { useTheme } from 'emotion-theming'
import addEventGA from 'utils/addEventGA'
import times from 'lodash/times'
import Loader from 'components/common/atoms/Loader'
import { useRouter } from 'next/router'
import AuthenticatedLink from 'components/common/molecules/AuthenticatedLink'

type Banner = {
  id: string
  name: string
  url: string
  recordLead: boolean
  action: string
  bigImageDesktop: {
    url: string
  }
  bigImageMobile: {
    url: string
  }
  brochure: {
    url: string
  } | null
}

type MobileCarouselProps = {
  interval?: number
  banners: Banner[] | undefined
}

const carouselStyle = (length: number) => css`
  overflow: hidden;
  position: relative;
  .carousel-content {
    display: flex;
    position: relative;
  }
  .carousel-item {
    flex-grow: 1;
    min-width: ${100 / (length + 2)}%;
    height: 140px;
    img {
      width: 100%;
      object-fit: fill;
      object-position: center;
    }
    @media screen and (min-width: 361px) {
      min-height: 146px;
    }
    @media screen and (min-width: 768px) {
      min-height: 180px;
    }
  }
`

const carouselIndicatorsStyle = (theme: ThemeType) => css`
  ${flexCenter}
  width: 100%;
  padding-top: 16px;
  list-style-type: none;
  li {
    width: 6px;
    height: 6px;
    background-color: ${theme.gray.gray30};
    border-radius: 100%;
    &:not(:last-child) {
      margin-right: 8px;
    }
    &.active {
      width: 16px;
      border-radius: 4px;
      background-color: ${theme.campus.aqua30};
    }
  }
  @media screen and (min-width: 768px) {
    position: unset;
    padding: 16px 0 0;
  }
`

const MobileCarousel = ({ interval = 10000, banners }: MobileCarouselProps) => {
  const length = banners?.length || 0
  const [active, setActive, handlers, style] = useCarousel(length, interval)

  const router = useRouter()
  const theme: ThemeType = useTheme()

  if (!banners) {
    return (
      <Container isMobileFull>
        <div className="carousel-skeleton">
          <Loader type="carousel" dataTestId="mobile-carousel-skeleton" />
          <ol
            className="carousel-indicators"
            css={carouselIndicatorsStyle(theme)}
            data-testid="mobile-carousel-indicators"
          >
            {times(3, (i) => (
              <li key={`carousel-indicator-${i}`} />
            ))}
          </ol>
        </div>
      </Container>
    )
  } else if (length > 0) {
    const firstBanner = length > 0 && banners[0]
    const lastBanner = length > 0 && banners[length - 1]

    const getUrl = (banner: Banner) => {
      if (banner.action !== 'url') {
        return banner.brochure?.url
      }
      return banner.url
    }

    const isOpenNewTab = (bannerAction: Banner['action']) => {
      return bannerAction !== 'url'
    }

    const handleClick = (banner: Banner) => {
      addEventGA('engagement', 'home', `ga/home/banner-other-service/${banner.name}`)
      isOpenNewTab(banner.action)
        ? window.open(getUrl(banner) || '#', 'blank', 'noopener noreferrer')
        : router.push(getUrl(banner) || '#')
    }
    return (
      <Container
        css={css`
          @media screen and (max-width: 767px) {
            max-width: unset;
            width: 100%;
            padding: 0;
          }
        `}
      >
        <div className="carousel" css={carouselStyle(length)}>
          <div className="carousel-content" {...handlers} style={style}>
            {lastBanner && (
              <div className={`carousel-item ${active === length - 1 ? 'active' : ''}`}>
                <AuthenticatedLink onClick={() => handleClick(lastBanner)} skipAuth={!lastBanner.recordLead}>
                  <Relazsizes
                    el="picture"
                    dataSrc={lastBanner?.bigImageMobile?.url}
                    dataSrcset={[{ media: '(min-width: 768px)', srcset: lastBanner?.bigImageDesktop?.url }]}
                    alt={lastBanner.name}
                  />
                </AuthenticatedLink>
              </div>
            )}
            {banners.map((banner: Banner, index: number) => (
              <div className={`carousel-item ${index === active ? 'active' : ''}`} key={index}>
                <AuthenticatedLink onClick={() => handleClick(banner)} skipAuth={!banner.recordLead}>
                  <Relazsizes
                    el="picture"
                    dataSrc={banner?.bigImageMobile?.url}
                    dataSrcset={[{ media: '(min-width: 768px)', srcset: banner?.bigImageDesktop?.url }]}
                    alt={banner.name}
                  />
                </AuthenticatedLink>
              </div>
            ))}
            {firstBanner && (
              <div className={`carousel-item ${active === 0 ? 'active' : ''}`}>
                <AuthenticatedLink onClick={() => handleClick(firstBanner)} skipAuth={!firstBanner.recordLead}>
                  <Relazsizes
                    el="picture"
                    dataSrc={firstBanner?.bigImageMobile?.url}
                    dataSrcset={[{ media: '(min-width: 768px)', srcset: firstBanner?.bigImageDesktop?.url }]}
                    alt={firstBanner.name}
                  />
                </AuthenticatedLink>
              </div>
            )}
          </div>
          <ol className="carousel-indicators" css={carouselIndicatorsStyle(theme)}>
            {banners.map((_: Banner, index: number) => (
              <li
                key={index}
                onClick={() => setActive(index)}
                onKeyDown={() => setActive(index)}
                className={`${active === index ? 'active' : ''}`}
              />
            ))}
          </ol>
        </div>
      </Container>
    )
  }
  return null
}

export default MobileCarousel
