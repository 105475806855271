import React from 'react'

import Relazsizes from 'relazsizes'
import { css } from '@emotion/core'
import { useRouter } from 'next/router'
import AuthenticatedLink from '../AuthenticatedLink'
import trackBannerLead from 'components/utils/trackBannerLead'

type BannerCarouselItemProps = {
  actionUrl?: string
  altText: string
  dataTestId?: string
  isActive: boolean
  imgMobileUrl: string
  imgDesktopUrl: string
  onClick?: () => void
  isOpenNewTab?: boolean
  recordLead: boolean
  id: string
}

const BannerCarouselItem: React.FC<BannerCarouselItemProps> = ({
  actionUrl = '#',
  altText,
  dataTestId,
  isActive,
  imgMobileUrl,
  imgDesktopUrl,
  onClick,
  isOpenNewTab = false,
  recordLead,
  id,
}) => {
  const router = useRouter()
  const handleClick = () => {
    onClick && onClick()
    recordLead && trackBannerLead(id)
    isOpenNewTab ? window.open(actionUrl, 'blank', 'noopener noreferrer') : router.push(actionUrl)
  }
  return (
    <div className={`carousel-item ${isActive ? 'active' : ''}`}>
      <AuthenticatedLink onClick={handleClick} data-testid={dataTestId} skipAuth={!recordLead}>
        <Relazsizes
          el="picture"
          src="/next-assets/images/skeleton.svg"
          dataSrc={imgMobileUrl}
          dataSrcset={[{ media: '(min-width: 768px)', srcset: imgDesktopUrl }]}
          alt={altText}
          css={css`
            &:-moz-loading {
              visibility: hidden;
            }
          `}
        />
      </AuthenticatedLink>
    </div>
  )
}

export default BannerCarouselItem
