import React, { FC, ReactNode } from 'react'

import Container from 'components/layouts/atoms/Container'
import NewTypography from 'components/common/atoms/NewTypography'
import Badge from 'components/common/atoms/Badge'
import SVGIcon from 'components/common/atoms/SVGIcon'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import flexCenter from 'utils/styles/flexCenter'
import addEventGA from 'utils/addEventGA'

import { MENU_ITEMS } from 'utils/constants/menuItems'

type MenuItemProps = {
  icon: ReactNode
  name: string
  url: string
  isNew?: boolean
  label: string
}

const menuItemStyle = (theme: ThemeType) => css`
  ${flexCenter}
  flex-basis: 25%;
  position: relative;
  flex-direction: column;
  padding: 8px;
  min-width: 80px;
  @media screen and (min-width: 768px) {
    flex-basis: inherit;
    flex-grow: 1;
  }
  .menu-item {
    &-icon {
      ${flexCenter}
      width: 48px;
      height: 48px;
      background-color: ${theme.campus.aqua30};
      border-radius: 8px;
      margin-bottom: 8px;
      @media screen and (min-width: 768px) {
        width: 56px;
        height: 56px;
      }
      transition: transform 500ms ease;
    }
    &-name {
      @media screen and (max-width: 767px) {
        font-size: 10px;
        font-weight: 12px;
      }
    }
  }
  &:hover {
    .menu-item-icon {
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
      transform: translateY(-8px);
    }
    span {
      color: ${theme.gray.gray70};
    }
  }
  .badge {
    position: absolute;
    top: -5px;
    right: 12%;
    @media screen and (min-width: 768px) {
      right: 30%;
      top: -3px;
    }
  }
`

const MenuItem: FC<MenuItemProps> = ({ icon, name, url, isNew, label }) => {
  const theme: ThemeType = useTheme()
  return (
    <a
      href={url}
      onClick={() => addEventGA('engagement', 'home', `ga/home/menu-main/${label}`)}
      className="menu-item"
      css={menuItemStyle(theme)}
    >
      <div className="menu-item-icon">
        <SVGIcon name={label} size="md" sizePC="lg" color="#fff">
          {icon}
        </SVGIcon>
      </div>
      <NewTypography variant="heading" level="3" color={theme.gray.gray50} className="menu-item-name">
        {name}
      </NewTypography>
      {isNew && <Badge text="BARU" background={theme.error.red40} />}
    </a>
  )
}

const menuSectionStyle = css`
  background-color: #ffffff;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;

  margin: 0 10px;
  @media screen and (min-width: 576px) {
    margin: 40px 0;
    padding: 8px 48px;

    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  }
`

const MenuSection = () => {
  return (
    <Container isMobileFull>
      <div className="menu-section" css={menuSectionStyle}>
        {MENU_ITEMS.map((item, idx) => (
          <MenuItem {...item} key={`menu-item-${idx}`} />
        ))}
      </div>
    </Container>
  )
}

export default MenuSection
