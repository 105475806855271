import React, { useContext } from 'react'

import SectionTitle from 'components/common/atoms/SectionTitle'
import SectionContainer from 'components/layouts/atoms/SectionContainer'
import Container from 'components/layouts/atoms/Container'
import TextLink from 'components/common/atoms/TextLink'
import CardFlexItemWrapper from 'components/cards/atoms/CardFlexItemWrapper'
import RecommendedCardsWrapper from 'components/common/atoms/RecommendedCardsWrapper'
import CampusCard from 'components/cards/organisms/CampusCard'

import { css } from '@emotion/core'
import { useQuery } from '@apollo/react-hooks'
import { GET_HOMEPAGE_TESTS } from '@graphql/queries/homepageQueries'
import { ProfileCtx } from 'components/utils/ProfileProvider'
import { useTheme } from 'emotion-theming'
import { stringifyUrl } from 'query-string'
import Loader from 'components/common/atoms/Loader'
import BasicContainer from 'components/common/atoms/BasicContainer'
import { triggerEvent } from 'utils/dataLayer'

type Assessment = {
  id: string
  image: {
    url: string
  } | null
  name: string
  label: string
  summary: string
  action: 'iframe' | 'url'
  actionUrl: string
  recordLead: boolean
  slug: string
}

const RecommendedTests = () => {
  const theme: ThemeType = useTheme()
  const { data, loading } = useQuery(GET_HOMEPAGE_TESTS)
  const assessments = data?.assessments

  const { state: profileState, incompleteVpMeta } = useContext(ProfileCtx)

  const getUrl = (assessment: Assessment) => {
    const detailTestUrl = `/tests/${assessment.slug}`
    const signinUrl = stringifyUrl({ url: '/signin', query: { isLead: true, successUrl: detailTestUrl } })
    const applicationUrl = stringifyUrl({ url: '/signup/application', query: { successUrl: detailTestUrl } })

    if (profileState.authStatus !== 'authorized' && assessment.recordLead) {
      return signinUrl
    }
    if (profileState.authStatus === 'authorized' && (!profileState.hasVpMeta || incompleteVpMeta)) {
      return applicationUrl
    }
    return detailTestUrl
  }

  const labelText = (label: string) => {
    switch (label) {
      case 'free':
        return 'GRATIS'
      case 'paid':
        return 'BERBAYAR'
      default:
        return ''
    }
  }
  return (
    <div className="recommended-tests">
      <SectionContainer smallPadding withPaddingBottom>
        <Container isMobileFull>
          <SectionTitle>Uji Potensi Rekomendasi Kami</SectionTitle>
          {/* upcoming update, to provide this part */}
          {/* <Typography tagName="p" variant="body" level="1">
            Kampus Rekomendasi Kami
          </Typography> */}
          <RecommendedCardsWrapper>
            <div
              className="campus-flex"
              css={css`
                display: flex;
                .campus-card {
                  height: 100%;
                }
              `}
            >
              {!loading
                ? assessments?.map((assessment: Assessment, i: number) => {
                    return (
                      <CardFlexItemWrapper key={assessment.id}>
                        <CampusCard
                          dataTestId={`test-${i}`}
                          imageUrl={assessment.image?.url}
                          title={assessment.name}
                          description={assessment.summary}
                          label={labelText(assessment.label)}
                          labelBackground={theme.neutral.white}
                          labelColor={theme.gray.gray70}
                          icon="assessment"
                          url={getUrl(assessment)}
                          linkProps={{
                            target: assessment.action === 'url' ? '_blank' : '',
                            rel: assessment.action === 'url' ? 'noreferrer noopener' : '',
                          }}
                          onClick={() => triggerEvent('home_test_rec', { test_name: assessment.name })}
                          authRequired
                        />
                      </CardFlexItemWrapper>
                    )
                  })
                : Array(5)
                    .fill(<Loader type="card" dataTestId="test-skeleton" />)
                    .map((CardSkeleton, idx) => (
                      <CardFlexItemWrapper key={`test-skeleton-${idx}`}>{CardSkeleton}</CardFlexItemWrapper>
                    ))}
            </div>
          </RecommendedCardsWrapper>
          <BasicContainer>
            <TextLink text="Lihat semua" href="/tests" className="recommended-tests__see-all" />
          </BasicContainer>
        </Container>
      </SectionContainer>
    </div>
  )
}

export default RecommendedTests
