import React from 'react'

import Typography from 'components/common/atoms/Typography'
import SectionContainer from 'components/layouts/atoms/SectionContainer'
import SectionTitle from 'components/common/atoms/SectionTitle'
import Container from 'components/layouts/atoms/Container'
import Relazsizes from 'relazsizes'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { useQuery } from '@apollo/react-hooks'
import { GET_HOMEPAGE_CITIES } from '@graphql/queries/homepageQueries'

import CardWrapper from 'components/cards/atoms/CardWrapper/Index'
import Ellipsis from 'components/common/atoms/Ellipsis'
import Loader from 'components/common/atoms/Loader'
import TextLink from 'components/common/atoms/TextLink'
import BasicContainer from 'components/common/atoms/BasicContainer'
import { triggerEvent } from 'utils/dataLayer'

type City = {
  id: string
  bannerImg: {
    url: string
  } | null
  name: string
  country: string
  description: string
  url: string
}

const recommendedCitiesStyle = css`
  .recommended-cities-items {
    padding: 16px 0 4px;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }
    }
    &__item {
      min-width: 48%;
      width: 48%;
      margin-bottom: 12px;
      &:nth-of-type(5) {
        display: none;
      }
    }
    @media screen and (min-width: 768px) {
      padding: 24px 0;
      &__item {
        min-width: 225px;
        width: 225px;
        height: 250px;
        margin-bottom: 0px;
        &:not(:last-of-type) {
          margin-right: 16px;
        }
        &:nth-of-type(5) {
          display: block;
        }
      }
    }
  }
`
const cityCardStyle = (theme: ThemeType) => css`
  .city-card {
    position: relative;
    width: 100%;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: ${theme.gray.gray70};
      opacity: 0.3;

      border-radius: 10px;
      transition: opacity 0.5s ease;
    }
    &-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
    @media screen and (min-width: 768px) {
      width: 225px;
      height: 250px;

      &:hover {
        &:before {
          opacity: 0.8;
        }
        .overlay-content > .city-description {
          display: block;
          margin-top: 12px;
        }
      }
    }

    .overlay-content {
      border-radius: 10px;
      padding: 16px;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      .city-name {
        margin-bottom: 4px;
        @media screen and (min-width: 768px) {
          margin-bottom: 8px;
        }
      }
      .city-description {
        display: none;
      }
    }
  }
`

const RecommendedCities = () => {
  const { data, loading } = useQuery(GET_HOMEPAGE_CITIES)
  const cities: City[] = data?.homepageCities
  const theme: ThemeType = useTheme()

  return (
    <div className="recommended-cities" css={recommendedCitiesStyle}>
      <SectionContainer backgroundColor={theme.gray.gray10} smallPadding withPaddingBottom>
        <Container isMobileFull>
          <SectionTitle>Kota Rekomendasi Kami</SectionTitle>
          <BasicContainer>
            <div className="recommended-cities-items">
              <div className="recommended-cities-items__wrapper">
                {!loading
                  ? cities?.map((city: City, i: number) => (
                      <div className="recommended-cities-items__item" key={city.id}>
                        <CardWrapper>
                          <a
                            href={city.url}
                            data-testid={`city-card-${i}`}
                            onClick={() => triggerEvent('home_city_rec', { campus_location: city.name })}
                            css={cityCardStyle(theme)}
                          >
                            <div className="city-card">
                              <Relazsizes
                                src="/next-assets/images/skeleton.svg"
                                dataSrc={city.bannerImg?.url || '/next-assets/images/placeholder_school_card.jpg'}
                                alt={city.name}
                                className="city-card-image"
                              />
                              <div className="overlay-content">
                                <Typography
                                  className="city-name"
                                  variant="body"
                                  level="3"
                                  color={theme.neutral.white}
                                  fontWeight="bold"
                                >
                                  {city.country}
                                </Typography>
                                <Typography variant="heading" level="1" color={theme.neutral.white}>
                                  {city.name}
                                </Typography>
                                <Typography
                                  className="city-description"
                                  tagName="div"
                                  variant="paragraph"
                                  level="2"
                                  color={theme.neutral.white}
                                >
                                  <Ellipsis text={city.description} color={theme.neutral.white} clamp={4} />
                                </Typography>
                              </div>
                            </div>
                          </a>
                        </CardWrapper>
                      </div>
                    ))
                  : Array(5)
                      .fill(<Loader type="smallCard" dataTestId="city-skeleton" />)
                      .map((CardSkeleton, idx) => (
                        <div className="recommended-cities-items__item" key={`city-skeleton-${idx}`}>
                          {CardSkeleton}
                        </div>
                      ))}
              </div>
            </div>
          </BasicContainer>
          <BasicContainer>
            <TextLink text="Lihat semua" href="/locations" className="recommended-cities__see-all" />
          </BasicContainer>
        </Container>
      </SectionContainer>
    </div>
  )
}

export default RecommendedCities
