import React, { useContext } from 'react'

import SectionContainer from 'components/layouts/atoms/SectionContainer'
import Container from 'components/layouts/atoms/Container'
import CampusCard from 'components/cards/organisms/CampusCard'
import CardFlexItemWrapper from 'components/cards/atoms/CardFlexItemWrapper'
import TextLink from 'components/common/atoms/TextLink'
import RecommendedCardsWrapper from 'components/common/atoms/RecommendedCardsWrapper'
import SectionTitle from 'components/common/atoms/SectionTitle'

import { css } from '@emotion/core'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_HOMEPAGE_MAJORS } from '@graphql/queries/homepageQueries'
import { LIKE_STUDY_FIELD } from '@graphql/mutations/likeStudyField'
import { ProfileCtx } from 'components/utils/ProfileProvider'
import Loader from 'components/common/atoms/Loader'
import BasicContainer from 'components/common/atoms/BasicContainer'
import { triggerEvent } from 'utils/dataLayer'

type Major = {
  id: string
  thumbnail: {
    url: string
  } | null
  name: string
  parentName: string
  liked: boolean
  slug: string
  summary: string
  iconName: string
}

const RecommendedMajors = () => {
  const { state: profileState } = useContext(ProfileCtx)
  const { data, loading } = useQuery(GET_HOMEPAGE_MAJORS)
  const studyFields = data?.studyFields

  const [likeStudyField] = useMutation(LIKE_STUDY_FIELD, {
    update(cache, { data: { likeStudyField } }) {
      const dataCache: { studyFields: Major[] } | null = cache.readQuery({
        query: GET_HOMEPAGE_MAJORS,
      })
      const studyFieldsCache = dataCache?.studyFields
      cache.writeQuery({
        query: GET_HOMEPAGE_MAJORS,
        data: {
          studyFields: studyFieldsCache?.map((studyField: Major) => {
            if (studyField.id === likeStudyField.id) {
              return { ...studyField, liked: likeStudyField.liked }
            }
            return studyField
          }),
        },
      })
    },
  })

  return (
    <div className="recommended-majors">
      <SectionContainer withPaddingBottom={profileState.authStatus !== 'authorized'} smallPadding>
        <Container isMobileFull>
          <SectionTitle>Jurusan Rekomendasi Kami</SectionTitle>
          {/* upcoming update, to provide this part */}
          {/* <Typography tagName="p" variant="body" level="1">
            Kampus Rekomendasi Kami
          </Typography> */}
          <RecommendedCardsWrapper>
            <div
              className="campus-flex"
              css={css`
                display: flex;
              `}
            >
              {!loading
                ? studyFields?.map((major: Major, i: number) => (
                    <CardFlexItemWrapper key={major.id}>
                      <CampusCard
                        dataTestId={`major-${i}`}
                        imageUrl={major.thumbnail?.url}
                        title={major.name}
                        key={major.id}
                        description={major.summary}
                        caption={major.parentName}
                        icon={major.iconName || 'major'}
                        kind="major"
                        url={`/majors/${major.slug}`}
                        isLikeable
                        isLiked={major.liked}
                        onClick={() => triggerEvent('home_major_rec', { major_name: major.name })}
                        likeAction={() =>
                          likeStudyField({
                            variables: { id: major.id },
                            optimisticResponse: {
                              __typename: 'Mutation',
                              likeStudyField: {
                                id: major.id,
                                kind: 'study_field',
                                liked: !major.liked,
                                __typename: 'LikeStudyFieldPayload',
                              },
                            },
                          })
                        }
                      />
                    </CardFlexItemWrapper>
                  ))
                : Array(5)
                    .fill(<Loader type="card" dataTestId="major-skeleton" />)
                    .map((CardSkeleton, idx) => (
                      <CardFlexItemWrapper key={`major-skeleton-${idx}`}>{CardSkeleton}</CardFlexItemWrapper>
                    ))}
            </div>
          </RecommendedCardsWrapper>
          <BasicContainer>
            <TextLink text="Lihat semua" href="/majors" className="recommended-majors__see-all" />
          </BasicContainer>
        </Container>
      </SectionContainer>
    </div>
  )
}

export default RecommendedMajors
