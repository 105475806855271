import React, { useContext } from 'react'
import { NextPage } from 'next'

import Home from 'components/containers/Home'
import CampusSeo from 'components/utils/CampusSeo'

import { withApollo } from 'components/utils/withApollo'
import { campusSSRApi } from 'utils/api'
import LoadingScreen from 'components/common/molecules/LoadingScreen'
import { ProfileCtx } from 'components/utils/ProfileProvider'

const Index: NextPage<{ seo: LooseObj }> = ({ seo }) => {
  const { state } = useContext(ProfileCtx)
  if (state.authStatus === 'idle') {
    return <LoadingScreen />
  }
  return (
    <div className="homepage">
      <CampusSeo seo={seo} />
      <Home />
    </div>
  )
}

Index.getInitialProps = async () => {
  const seoRespose = await campusSSRApi('api/seo?path=/')
  const seoJson = await seoRespose.json()

  return { seo: seoJson }
}

export default withApollo({ ssr: true })(Index)
