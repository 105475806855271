import React from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import Relazsizes from 'relazsizes'

import Container from 'components/layouts/atoms/Container'
import SectionContainer from 'components/layouts/atoms/SectionContainer'
import Typography from 'components/common/atoms/Typography'
import SectionTitle from 'components/common/atoms/SectionTitle'

import WhyQCData from './WhyQCData.json'
import BasicContainer from 'components/common/atoms/BasicContainer'

const whyQCStyle = css`
  .why-qc {
    &__wrapper {
      margin-top: 40px;
      display: flex;
    }
    &__item {
      &:not(:first-of-type) {
        margin-left: 160px;
      }
      img {
        height: 180px;
      }
    }
    &__text {
      margin-top: 24px;
      p {
        margin-top: 16px;
      }
    }
    @media screen and (max-width: 767px) {
      &__wrapper {
        margin-top: 24px;
        flex-direction: column;
      }
      &__item {
        display: flex;
        &:not(:first-of-type) {
          margin-left: 0;
          margin-top: 24px;
        }
        img {
          height: 79px;
        }
      }
      &__text {
        margin-left: 16px;
        margin-top: 0;
        p {
          margin-top: 12px;
        }
      }
    }
  }
`

const WhyQC = () => {
  const theme: ThemeType = useTheme()

  return (
    <div className="why-qc" css={whyQCStyle}>
      <SectionContainer backgroundColor={theme.gray.gray10} withPaddingBottom smallPadding>
        <Container isMobileFull>
          <SectionTitle>Kenapa Memilih Quipper Campus?</SectionTitle>
          <BasicContainer>
            <div className="why-qc__wrapper">
              {WhyQCData.map((item) => (
                <div className="why-qc__item" key={item.thumbnail}>
                  <Relazsizes
                    dataSrc={`/next-assets/images/homepage/why-qc/${item.thumbnail}_2x.png`}
                    alt={item.title}
                  />
                  <div className="why-qc__text">
                    <Typography variant="heading" level="1" fontWeight="bold" tagName="h3">
                      {item.title}
                    </Typography>
                    <Typography variant="paragraph" level="1" tagName="p">
                      {item.description}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </BasicContainer>
        </Container>
      </SectionContainer>
    </div>
  )
}

export default WhyQC
