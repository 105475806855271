import gql from 'graphql-tag'

export const LIKE_STUDY_FIELD = gql`
  mutation LikeStudyField($id: String!) {
    likeStudyField(input: { id: $id }) {
      id
      kind
      liked
    }
  }
`
