import gql from 'graphql-tag'

export const LIKE_PROFESSION = gql`
  mutation LikeProfession($id: String!) {
    likeProfession(input: { id: $id }) {
      id
      kind
      liked
    }
  }
`
