import React from 'react'

import SectionTitle from 'components/common/atoms/SectionTitle'
import SectionContainer from 'components/layouts/atoms/SectionContainer'
import Container from 'components/layouts/atoms/Container'
import CampusCard from 'components/cards/organisms/CampusCard'
import CardFlexItemWrapper from 'components/cards/atoms/CardFlexItemWrapper'
import TextLink from 'components/common/atoms/TextLink'
import RecommendedCardsWrapper from 'components/common/atoms/RecommendedCardsWrapper'

import { css } from '@emotion/core'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_HOMEPAGE_CAREERS } from '@graphql/queries/homepageQueries'
import { LIKE_PROFESSION } from '@graphql/mutations/likeProfession'
import Loader from 'components/common/atoms/Loader'
import BasicContainer from 'components/common/atoms/BasicContainer'
import { triggerEvent } from 'utils/dataLayer'

type Career = {
  id: string
  thumbnail: {
    url: string
  } | null
  name: string
  fieldName: string
  iconName: string
  liked: boolean
  slug: string
  summary: string
  minSalary: number
  maxSalary: number
}

const RecommendedCareers = () => {
  const { data, loading } = useQuery(GET_HOMEPAGE_CAREERS)
  const careers: Career[] = data?.careers

  const [likeProfession] = useMutation(LIKE_PROFESSION, {
    update(cache, { data: { likeProfession } }) {
      const dataCache: { careers: Career[] } | null = cache.readQuery({
        query: GET_HOMEPAGE_CAREERS,
      })
      const careersCache = dataCache?.careers
      cache.writeQuery({
        query: GET_HOMEPAGE_CAREERS,
        data: {
          careers: careersCache?.map((career: Career) => {
            if (career.id === likeProfession.id) {
              return { ...career, liked: likeProfession.liked }
            }
            return career
          }),
        },
      })
    },
  })

  return (
    <div className="recommended-careers">
      <SectionContainer smallPadding>
        <Container isMobileFull>
          <SectionTitle>Karir Rekomendasi Kami</SectionTitle>
          {/* upcoming update, to provide this part */}
          {/* <Typography tagName="p" variant="body" level="1">
            Kampus Rekomendasi Kami
          </Typography> */}
          <RecommendedCardsWrapper>
            <div
              className="campus-flex"
              css={css`
                display: flex;
              `}
            >
              {!loading
                ? careers?.map((career: Career, i: number) => (
                    <CardFlexItemWrapper key={career.id}>
                      <CampusCard
                        dataTestId={`career-${i}`}
                        imageUrl={career.thumbnail?.url}
                        title={career.name}
                        key={career.id}
                        description={career.summary}
                        caption={career.fieldName}
                        icon="career"
                        url={`/careers/${career.slug}`}
                        isLikeable
                        isLiked={career.liked}
                        onClick={() => triggerEvent('home_career_rec', { career_name: career.name })}
                        likeAction={() =>
                          likeProfession({
                            variables: { id: career.id },
                            optimisticResponse: {
                              __typename: 'Mutation',
                              likeProfession: {
                                id: career.id,
                                kind: 'profession',
                                liked: !career.liked,
                                __typename: 'LikeProfessionPayload',
                              },
                            },
                          })
                        }
                      />
                    </CardFlexItemWrapper>
                  ))
                : Array(5)
                    .fill(<Loader type="card" dataTestId="career-skeleton" />)
                    .map((CardSkeleton, idx) => (
                      <CardFlexItemWrapper key={`career-skeleton-${idx}`}>{CardSkeleton}</CardFlexItemWrapper>
                    ))}
            </div>
          </RecommendedCardsWrapper>
          <BasicContainer>
            <TextLink text="Lihat semua" href="/careers" className="recommended-careers__see-all" />
          </BasicContainer>
        </Container>
      </SectionContainer>
    </div>
  )
}

export default RecommendedCareers
