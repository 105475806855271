import gql from 'graphql-tag'

export const TRACK_BANNER = gql`
  mutation trackBanner($id: String!) {
    trackBanner(input: { id: $id }) {
      id
      status
      tracked
    }
  }
`
