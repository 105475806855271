import React from 'react'

import Container from 'components/layouts/atoms/Container'
import Loader from 'components/common/atoms/Loader'
import SVGIcon from 'components/common/atoms/SVGIcon'
import LeftSVG from 'svgs/left.svg'
import RightSVG from 'svgs/right.svg'
import BannerCarouselItem from 'components/common/molecules/BannerCarouselItem'

import { useCarousel } from 'utils/hooks/useCarousel'
import { useQuery } from '@apollo/react-hooks'
import { GET_HOMEPAGE_CAROUSEL_BANNERS } from '@graphql/queries/homepageQueries'

import { css } from '@emotion/core'
import flexCenter from 'utils/styles/flexCenter'
import { useTheme } from 'emotion-theming'

import { triggerEvent } from 'utils/dataLayer'
import times from 'lodash/times'

type Banner = {
  id: string
  name: string
  url: string
  recordLead: boolean
  action: string
  bigImageDesktop: {
    url: string
  }
  bigImageMobile: {
    url: string
  }
  brochure: {
    url: string
  } | null
  homepageType: string
}

type BannersCarouselProps = {
  interval?: number
}

const carouselStyle = (active: number, length: number, theme: ThemeType) => css`
  overflow: hidden;
  position: relative;
  .carousel-content {
    display: flex;
    position: relative;
    height: 188px;
  }
  .carousel-item {
    flex-grow: 1;
    min-width: ${100 / (length + 2)}%;
    img {
      width: 100%;
      height: 188px;
      object-fit: cover;
      object-position: center;
    }
  }
  .carousel-navigator {
    display: none;
  }
  @media screen and (min-width: 768px) {
    margin-top: 40px;

    &:hover {
      .carousel-navigator {
        opacity: 1;
      }
    }

    .carousel-navigator {
      opacity: 0;
      transition: opacity 0.25s ease;
      position: absolute;
      top: 75px;
      width: 32px;
      height: 40px;
      border-radius: 4px;
      background-color: ${theme.neutral.white};
      box-shadow: 0 2px 8px 0 rgba(26, 52, 79, 0.4);
      z-index: 1;
      cursor: pointer;
      ${flexCenter}
      &.prev {
        left: 312px;
      }
      &.next {
        right: 320px;
      }
    }
    .carousel-content {
      width: ${576 * (length + 2)}px !important;
      left: -${(active + 1) * 288 + 16 * active}px !important;
    }
    .carousel-item {
      margin-right: 16px;
      border-radius: 10px;
      transform: translateX(-${288 * active}px) scaleX(0.98) scaleY(0.95);
      img {
        border-radius: 10px;
        object-fit: fill;
      }
      &.active {
        transform: translateX(-${288 * active}px) scale(1) scaleY(1);
      }
    }
  }
`

const carouselIndicatorsStyle = (theme: ThemeType) => css`
  ${flexCenter}
  width: 100%;
  padding: 12px;
  list-style-type: none;
  li {
    width: 6px;
    height: 6px;
    background-color: ${theme.gray.gray30};
    border-radius: 100%;
    &:not(:last-child) {
      margin-right: 8px;
    }
    &.active {
      width: 16px;
      border-radius: 4px;
      background-color: ${theme.campus.aqua30};
    }
  }
  @media screen and (min-width: 768px) {
    position: unset;
    padding: 16px 0 0;
  }
`

const queryVars = {
  imageDesktopSize: '1176x360#c',
  imageMobileSize: '720x360#c',
}

const BannersCarousel = ({ interval = 10000 }: BannersCarouselProps) => {
  const { data: bannersResult, loading } = useQuery(GET_HOMEPAGE_CAROUSEL_BANNERS, {
    variables: queryVars,
  })

  const banners = bannersResult?.banners
  const mainBanners = banners?.filter((banner: Banner) => banner.homepageType === 'main')
  const length = mainBanners?.length || 0
  const firstBanner = length > 0 && mainBanners[0]
  const lastBanner = length > 0 && mainBanners[length - 1]
  const [active, setActive, handlers, style] = useCarousel(length, interval)

  const theme: ThemeType = useTheme()

  const getUrl = (banner: Banner) => {
    if (banner.action !== 'url') {
      return banner.brochure?.url
    }
    return banner.url
  }

  const isOpenNewTab = (bannerAction: Banner['action']) => {
    return bannerAction !== 'url'
  }

  const prev = () => {
    const index = active > 0 ? active : length
    setActive(index - 1)
  }

  const next = () => {
    const index = active !== length - 1 ? active + 1 : 0
    setActive(index)
  }

  if (loading || length > 0) {
    return (
      <Container
        css={css`
          @media screen and (max-width: 767px) {
            max-width: unset;
            width: 100%;
            padding: 0;
          }
        `}
      >
        <div className="carousel" css={carouselStyle(active, length, theme)}>
          <div className="carousel-navigator prev" onClick={prev} onKeyDown={prev}>
            <SVGIcon name="left" size="md" color={theme.gray.gray50}>
              <LeftSVG />
            </SVGIcon>
          </div>
          <div className="carousel-navigator next" onClick={next} onKeyDown={next}>
            <SVGIcon name="right" size="md" color={theme.gray.gray50}>
              <RightSVG />
            </SVGIcon>
          </div>
          <div className="carousel-content" {...handlers} style={style}>
            {loading ? (
              times(5, (i) => (
                <Loader
                  key={`carousel-skeleton-${i}`}
                  className={`carousel-item ${i === 1 ? 'active' : ''}`}
                  type="banner"
                  dataTestId={`carousel-skeleton-${i}`}
                />
              ))
            ) : (
              <>
                <BannerCarouselItem
                  id={lastBanner.id}
                  altText={lastBanner.name}
                  actionUrl={getUrl(lastBanner)}
                  dataTestId={`main-banner-${length}`}
                  imgMobileUrl={lastBanner?.bigImageMobile?.url}
                  imgDesktopUrl={lastBanner?.bigImageDesktop?.url}
                  isActive={active === length - 1}
                  onClick={() => triggerEvent('home_main_banner', { banner_name: lastBanner.name})}
                  isOpenNewTab={isOpenNewTab(lastBanner.action)}
                  recordLead={lastBanner.recordLead}
                />
                {mainBanners.map((banner: Banner, index: number) => (
                  <BannerCarouselItem
                    id={banner.id}
                    altText={banner.name}
                    actionUrl={getUrl(banner)}
                    dataTestId={`main-banner-i-${index}`}
                    imgMobileUrl={banner?.bigImageMobile?.url}
                    imgDesktopUrl={banner?.bigImageDesktop?.url}
                    isActive={active === index}
                    onClick={() => triggerEvent('home_main_banner', { banner_name: banner.name})}
                    key={`banner-item-${banner.id}-${index}`}
                    isOpenNewTab={isOpenNewTab(banner.action)}
                    recordLead={banner.recordLead}
                  />
                ))}
                <BannerCarouselItem
                  id={firstBanner.id}
                  altText={firstBanner.name}
                  actionUrl={getUrl(firstBanner)}
                  dataTestId="main-banner-0"
                  imgMobileUrl={firstBanner?.bigImageMobile?.url}
                  imgDesktopUrl={firstBanner?.bigImageDesktop?.url}
                  isActive={active === 0}
                  onClick={() => triggerEvent('home_main_banner', { banner_name: firstBanner.name})}
                  isOpenNewTab={isOpenNewTab(firstBanner.action)}
                  recordLead={firstBanner.recordLead}
                />
              </>
            )}
          </div>
          <ol className="carousel-indicators" css={carouselIndicatorsStyle(theme)}>
            {loading
              ? times(3, (i) => (
                  <li key={`banner-carousel-indicator-${i}`} data-testid={`carousel-indicator-skeleton-${i}`} />
                ))
              : mainBanners.map((_: Banner, index: number) => (
                  <li
                    key={index}
                    className={`${active === index ? 'active' : ''}`}
                    data-testid={`carousel-indicator-${index}`}
                  />
                ))}
          </ol>
        </div>
      </Container>
    )
  }
  return null
}

export default BannersCarousel
